/* eslint-disable max-len */

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Page,
  Text,
  Link,
  View,
  Image,
  Document,
  Font,
  StyleSheet,
  PDFDownloadLink,
} from '@react-pdf/renderer';

import Types from '../common/modules/types';

const formatLabel = (label) => label
  ?.toLowerCase()
  ?.replace(/_/g, ' ')
  ?.replace(/\b\w/g, (char) => char?.toUpperCase());

const formatDate = (date) => moment(date)?.format('MMM DD, YYYY');

const formatDescription = (description, type) => (
  description.replace(
    /{TYPE}/g,
    Types.VISUAL_MARKETING_TYPE_MAP[type]?.shortLabel,
  )
);

const resolveNestedKey = (obj, path) => path
  .split('.')
  .reduce((acc, part) => acc && acc[part], obj);

Font.register({
  family: 'Poppins',
  fonts: [
    { src: '/pdf-assets/poppins-regular.ttf', fontWeight: 'normal' },
    { src: '/pdf-assets/poppins-medium.ttf', fontWeight: 'medium' },
    { src: '/pdf-assets/poppins-bold.ttf', fontWeight: 'bold' },
  ],
});

Font.registerHyphenationCallback(word => [word]);

const Wrapper = styled.div`
  display: inline-block;

  @media(max-width: 768px) {
    display: block;
    width: 100%;
    button {
      width: 100%;
      margin-top: 10px;
    }
  }
`;

const SPECS = [
  { label: 'Date', key: 'createdAt', func: formatDate },
  { label: 'Type', key: 'marketingType', func: formatLabel },
  { label: 'Goal', key: 'goal', func: formatLabel },
  { label: 'Season', key: 'season', func: formatLabel },
  { label: 'Brand', key: 'brandData.name', func: formatLabel },
  { label: 'Category', key: 'categoryData.name', func: formatLabel },
  { label: 'Retailer', key: 'retailerData.name', func: formatLabel },
];

const SCORES = [{
  key: 'visibilityScore',
  label: 'Stopping Power',
  description: 'How effective is the {TYPE} in\nattracting shoppers\' attention?',
}, {
  key: 'engagementScore',
  label: 'Engagement',
  description: 'How engaging is the {TYPE}\ntowards making a purchase?',
}];

const METRICS = {
  SHELF_READY_PACKAGING: [{
    key: 'srpBrandProminence',
    label: 'Brand Prominence',
    description: 'How well does the SRP support branding?',
  }, {
    key: 'srpVisualContrast',
    label: 'Visual Contrast',
    description: 'How well does the SRP visually stand out?',
  }, {
    key: 'srpCreativity',
    label: 'Creativity',
    description: 'How good is the SRP visual communication?',
  }, {
    key: 'srpShopperMessage',
    label: 'Shopper Message',
    description: 'How visible & clear is the shopper message?',
  }, {
    key: 'srpFunctional',
    label: 'Functional',
    description: 'How well does SRP keep the shelf organized?',
  }, {
    key: 'srpAlignment',
    label: 'Alignment',
    description: 'How aligned is SRP with primary packaging?',
  }],
  DEFAULT: [{
    key: 'promotionVisibility',
    label: 'Price Promotion',
    description: 'How visible is the off-price promotion?',
  }, {
    key: 'productExposure',
    label: 'Product Exposure',
    description: 'How visible/accessible are the products?',
  }, {
    key: 'ctaVisibility',
    label: 'Call To Action',
    description: 'How visible is the main message?',
  }, {
    key: 'keyVisual',
    label: 'Key Visual',
    description: 'How visible are the key graphics?',
  }, {
    key: 'textBalance',
    label: 'Text Balance',
    description: 'How condensed is the textual content?',
  }, {
    key: 'visualSimplicity',
    label: 'Visual Simplicity',
    description: 'How clear is the visual communication?',
  }, {
    key: 'shoppingExperienceScore',
    label: 'Shopping Experience',
    description: 'How inspiring & creative is the {TYPE}?',
  }, {
    key: 'brandingScore',
    label: 'Branding',
    description: 'How aligned is the {TYPE} with the brand?',
  }],
};

const CASE_STUDY = [{
  title: 'Improved Product Exposure',
  description: 'By larger bottle image and shaped header.',
}, {
  title: 'Changed Key Visual',
  description: 'New position and brand name.',
}, {
  title: 'Open Structure',
  description: 'Allows better product visibility.',
}, {
  title: 'Less Bottles',
  description: 'Enabling structural design to function.',
}, {
  title: 'Increased Bottle Image',
  description: 'Within side visual.',
}, {
  title: 'Bigger Brand Logo',
  description: 'Increased brand recognition.',
}];

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  headerLogo: {
    width: 'auto',
    maxWidth: 160,
    height: 'auto',
    maxHeight: 68,
    objectFit: 'contain',
    objectPosition: 'left',
    marginBottom: 10,
  },
  coverImageWrapper: {
    position: 'relative',
    width: 'auto',
    maxWidth: '50%',
  },
  coverImage: {
    width: 'auto',
    maxWidth: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  blockImageWrapper: {
    position: 'relative',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockImage: {
    width: 'auto',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  shade: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#222',
    opacity: 0.05,
  },
  scale: {
    position: 'absolute',
    top: 164,
    right: 54,
    width: 'auto',
    height: 16.7,
    objectFit: 'contain',
  },
  section: {
    flexDirection: 'column',
    padding: 50,
  },
  block: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 30,
  },
  title: { fontFamily: 'Poppins', fontSize: 34, fontWeight: 'bold' },
  subtitle: { fontFamily: 'Poppins', fontSize: 26, fontWeight: 'bold' },
  caption: { fontFamily: 'Poppins', fontSize: 16, fontWeight: 'bold' },
  text: { fontFamily: 'Poppins', fontSize: 9.6, fontWeight: 'medium' },
  textBold: { fontFamily: 'Poppins', fontSize: 9.6, fontWeight: 'bold' },
  label: {
    fontFamily: 'Poppins',
    fontWeight: 'medium',
    fontSize: 8,
    letterSpacing: 4,
    textTransform: 'uppercase',
    opacity: 0.5,
  },
  caseStudy: {
    position: 'absolute',
    top: 60,
    right: 30,
    width: 'auto',
    height: 480,
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

const Footer = ({ page }) => (
  <View
    style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      position: 'absolute',
      bottom: 25,
      left: 25,
      right: 25,
    }}
  >
    <Image
      src="/pdf-assets/logo.png"
      style={{
        height: 14,
        width: 'auto',
        objectFit: 'contain',
      }}
    />
    <Text style={styles.label}>{page}</Text>
  </View>
);

const Rating = ({ score, shape = 'star' }) => (
  <View style={{ flexDirection: 'row', gap: 5 }}>
    {[...Array(5)].map((_, index) => (
      <Image
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        src={index < score
          ? `/pdf-assets/${shape}-filled.png`
          : `/pdf-assets/${shape}-empty.png`}
        style={{ height: 24, width: 'auto', objectFit: 'contain' }}
      />
    ))}
  </View>
);

const Report = ({
  data,
  logoSrc = '/pdf-assets/logo.png',
  includeCaseStudy,
}) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={{ ...styles.section, justifyContent: 'space-between', width: '50%' }}>
        <View style={styles.block}>
          <Image src={logoSrc} style={styles.headerLogo} />
          <Text style={styles.title}>{data.name}</Text>
          <Text style={styles.label}>| Report</Text>
        </View>
        <View style={styles.row}>
          {SPECS.map(({ label, key, func }) => resolveNestedKey(data, key) && (
            <View style={styles.block} key={key}>
              <Text style={styles.label}>{label}</Text>
              <Text style={styles.textBold}>
              {func(resolveNestedKey(data, key))}
              </Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.coverImageWrapper}>
        <Image
          src={data?.image?.src}
          style={styles.coverImage}
        />
        <View style={styles.shade} />
      </View>
    </Page>

    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={{ ...styles.section, paddingBottom: 80, justifyContent: 'space-between' }}>
        <View style={{ ...styles.block, gap: 20 }}>
          <View style={styles.block}>
            <Text style={styles.label}>| Powered By</Text>
            <Text style={styles.title}>Shopnosis.AI</Text>
          </View>
          <View style={{ ...styles.block, gap: 15, opacity: 0.8 }}>
            <Text>
              <Text style={{ ...styles.text, fontWeight: 'medium', textDecoration: 'underline' }}>Shopnosis.AI </Text>
              <Text style={{ ...styles.text, fontWeight: 'medium' }}>revolutionizes Retail Marketing Planning for leading CPG brands by optimizing in-store marketing campaigns through real shopper insights.</Text>
            </Text>
            <Text>
              <Text style={{ ...styles.text, fontWeight: 'medium', textDecoration: 'underline' }}>Shopnosis.AI </Text>
              <Text style={{ ...styles.text, fontWeight: 'medium' }}>leverages data from 10+ million real-world shopping interactions captured via smart glasses. By analyzing these interactions, Shopnosis AI quantifies the effectiveness of over 10,000 in-store campaigns in attracting and converting shoppers. The data is derived from our Store Impact in-store analytics program, which utilizes computer vision and AI technology to analyze grocery shopping trips. With genuine shoppers wearing camera smart glasses in supermarkets, on three continents and an expanding database of shopping trips. Shopnosis.AI algorithm utilizes image and text recognition capabilities, considering over 100 parameters such as call-to-action wording, sentiment and looks, key visual elements, contrast, colors etc.</Text>
            </Text>
            <Text>
              <Text style={{ ...styles.text, fontWeight: 'medium', textDecoration: 'underline' }}>Shopnosis.AI </Text>
              <Text style={{ ...styles.text, fontWeight: 'medium' }}>is the only AI trained exclusively on real in-store data, providing highly predictive results for in-store marketing performance. To predict performance of in-store marketing it is crucial to have data from real in-store contexts & real shopping trips. Other algorithms trained on screen data or data outside of store are just not good enough to predict in-store performance.</Text>
            </Text>
          </View>
        </View>
        <View style={{ ...styles.row, justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <View style={{ ...styles.block, gap: 2 }}>
            <Text style={{ ...styles.label, opacity: 1 }}>Shopnosis.AI</Text>
            <Text style={{ ...styles.label, opacity: 1 }}>Learns From:</Text>
            <View style={{ height: 5 }} />
            <Text style={styles.text}>• More than 10.000 in-store campaigns</Text>
            <Text style={styles.text}>• Real shopping trips on three continents</Text>
            <Text style={styles.text}>• Leading global retailers</Text>
          </View>
          <Image src="/pdf-assets/retailers.png" style={{ height: 160, width: 'auto', objectFit: 'contain', opacity: 0.6 }} />
        </View>
        <Footer page={2} />
      </View>
    </Page>

    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={{ ...styles.section, ...styles.blockImageWrapper }}>
        <Image
          src={data?.image?.src}
          style={styles.blockImage}
        />
        <View style={{ ...styles.shade, top: 50, right: 50, bottom: 50, left: 50 }} />
      </View>
      <View style={{ ...styles.section, paddingLeft: 0, justifyContent: 'space-between', width: '50%' }}>
        <View style={styles.block}>
        <Text style={{ ...styles.subtitle, fontSize: 21 }}>{data.name.length >= 30 ? `${data.name.substring(0, 28)}...` : data.name}</Text>
          <View style={{ flexDirection: 'row', gap: 10, justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Text style={styles.label}>| Results</Text>
            <Link href="https://shopnosis.notion.site/How-to-interpret-the-results-b3473fdbaa73445e8495ebf144ee44ce">
              <Text style={{ ...styles.text, color: '#4885ff', textDecoration: 'none', fontSize: 8 }}>
                How to interpret?
              </Text>
            </Link>
          </View>
        </View>
        <Image style={styles.scale} src="/pdf-assets/scale.png" />
        <View style={{ ...styles.block, gap: 10, marginTop: 20 }}>
          {SCORES.map(({ key, label, description }) => (
            <View key={key} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ ...styles.block }}>
                <Text style={styles.caption}>{label}</Text>
                <Text style={{ ...styles.text, opacity: 0.5 }}>{formatDescription(description, data.marketingType)}</Text>
              </View>
              <Rating score={data[key]} shape="box" />
            </View>
          ))}
        </View>
        <View style={{ flex: 1 }} />
        <View style={{ ...styles.block, gap: 5 }}>
          {(METRICS[data.marketingType] || METRICS.DEFAULT).map(({ key, label, description }) => (
            <View key={key} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', opacity: (data?.[key] > 0 ? 1 : 0.3) }}>
              <View style={{ ...styles.block }}>
                <Text style={{ ...styles.caption, fontSize: 12 }}>{label}</Text>
                <Text style={{ ...styles.text, opacity: 0.5 }}>{formatDescription(description, data.marketingType)}</Text>
              </View>
              <Rating score={data[key]} />
            </View>
          ))}
        </View>
      </View>
    </Page>

    {
      data?.improvementSuggestions?.length > 0
      ? (
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={{ ...styles.section, paddingBottom: 60, justifyContent: 'space-between' }}>
            <View style={{ ...styles.block, gap: 15 }}>
              <View style={styles.block}>
                <Text style={styles.subtitle}>Recommendations</Text>
                <Text style={styles.label}>| For Improvement</Text>
              </View>
              <View style={{ ...styles.block, gap: 8, padding: '10px 20px' }}>
                {data?.improvementSuggestions?.slice(0, 5).map((item, index) => (
                  <View key={item.element} style={{ flexDirection: 'row', gap: 10 }}>
                    <Text style={{ ...styles.caption, fontSize: 14 }}>
                      {index + 1}
                      .
                    </Text>
                  <View style={styles.block}>
                    <Text style={{ ...styles.caption, fontSize: 14 }}>{formatLabel(item.issue)}</Text>
                    <Text style={{ ...styles.text, opacity: 0.75 }}>{item.suggestion}</Text>
                  </View>
                  </View>
                ))}
              </View>
            </View>
            <Footer page={4} />
          </View>
        </Page>
      )
      : null
    }

    {(includeCaseStudy && data.marketingType !== 'SHELF_READY_PACKAGING') ? (
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={{ ...styles.section, paddingBottom: 100, width: '100%', justifyContent: 'space-between' }}>
          <View style={styles.block}>
            <Text style={styles.subtitle}>Case Study</Text>
            <Text style={styles.label}>| How to Implement</Text>
            <Text style={styles.label}>| Results & Grow Sales</Text>
          </View>
          <View style={{ ...styles.block, gap: 15 }}>
            {CASE_STUDY.map((item) => (
              <View key={item.title} style={styles.block}>
                <Text style={{ ...styles.caption, fontSize: 14 }}>{formatLabel(item.title)}</Text>
                <Text style={{ ...styles.text, opacity: 0.75 }}>{item.description}</Text>
              </View>
            ))}
          </View>
          <Image style={styles.caseStudy} src="/pdf-assets/case-study.png" />
        </View>
        <Footer
          page={(
            data?.improvementSuggestions?.length > 0
            ? 5
            : 4
          )}
        />
      </Page>
    ) : null}

    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={{ ...styles.section, width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ ...styles.block, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Image
            src="/pdf-assets/linkedin.png"
            style={{
              height: 156,
              width: 'auto',
              objectFit: 'contain',
            }}
          />
        </View>
        <View style={{ ...styles.block, gap: 15, alignItems: 'center' }}>
          <Image
            src="/pdf-assets/logo.png"
            style={{
              height: 40,
              width: 'auto',
              objectFit: 'contain',
            }}
          />
          <Text style={styles.label}>Your AI Assistant for Retail Marketing Planning</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export function DownloadReport({
  fileName,
  children,
  ...props
}) {
  return (
    <Wrapper>
      <PDFDownloadLink
        document={<Report {...props} />}
        fileName={fileName}
      >
        {children}
        {/* eslint-disable-next-line no-unused-vars */}
        {/* {({ blob, url, loading, error }) => (
          loading ? 'Loading...' : children)} */}
      </PDFDownloadLink>
    </Wrapper>
  );
}

export default Report;
